import React from 'react'

const LocationLogo = (logoObj) => {
    const {url, description} = logoObj.logoObj.asset
    
    if (url) {
    	return (
    		<div className="sl_logo">
	        	<img src={url} alt={description}></img>
	      	</div>
    	);
    } else {

    }
};

export default LocationLogo;