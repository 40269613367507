import React from 'react'

const SideBarContent = (props) => {
  
  const childDesc = props.props.descLong != null ? props.props.descLong[0].children[0] : null
  return (
    <>
      {props.props.descLong != null ? <p>{childDesc.text}</p> : null}
      <div className="sl_sidebar__cta show-for-large">
      <h3>Can we help you?</h3>
      <p>Call for more information or an assessment.</p>
      {props.props.number != null ? <a className="sl_button sl_button--phone-alt" href={`tel:${props.props.number}`}>{props.props.number}</a> : null}
      {props.props.link != null ?<a className="sl_button sl_button--border-alt sl_button--external" target="_blank" rel="noopener" href={props.props.link.home}>Visit Website</a>: null}
      </div>
    </>
  );
};

export default SideBarContent;