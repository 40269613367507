import React from 'react'

const SideBarInfo = ({url, number}) => {
              
    return (
      <>
        {url != null ? <p><strong>Website:</strong> {url.home && (
          <a href={url.home} target="_blank" rel="noopener">{url.home}</a>
        ) } </p> : null}
        {number != null ?<p><strong>Phone:</strong> {number && (
          <a href={`tel:${number}`}>{number}</a>
        )} </p>  : null}
      </>
    );
};

export default SideBarInfo;