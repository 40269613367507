import React from 'react';

const LocationDetails = ({services, specialty}) => {

    const diagnosisOutput = [...new Map(services.map(o => [o.diagnosis.name, o])).values()]
    const canDetailsRender = services;
    
    if (!canDetailsRender) {
      return null;
    }

    return (
      <>
        <h3>Treatment Details</h3>
        <div className="sl_callout">
            <h4>Levels of Care</h4>
                {diagnosisOutput.map(( node ) => (
                    <>
                    <h5>{node.diagnosis.name}</h5>
                    <ul>
                        {services.map(( item ) => (
                            (node.diagnosis.name === item.diagnosis.name) ?
                            <>
                            <li key={item.id}>
                                {item.levelCare.name} Treatment for ages {item.age.min} { (item.age.max !== null) ? `to ` + item.age.max : 'and up'} 
                            </li>
                            </>
                            : null
                        ))}
                    </ul>
                    </>
                ))}
        </div>
        <div className="sl_callout">
            <h4>Specialty Programs</h4>
            <ul>
            {specialty.map(( node ) => (
                <li key={node.id}>{node.name}</li>
            ))}
            </ul>
        </div>
      </>
    );
  };
  export default LocationDetails;