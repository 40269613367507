import React from 'react'
import {graphql} from 'gatsby'
import { Link } from "gatsby"
import Container from '../components/base/container'
import GraphQLErrorList from '../components/base/graphql-error-list'
import SEO from '../components/base/seo'
import Layout from "../components/base/layout"
import LocationHeader from "../components/base/location-header"
import LocationLogo from '../components/location/logo'
import SideBarInfo from '../components/location/sidebarinfo'
import HoursOfOperation from '../components/location/hoursofoperation'
import MapAddressComponent from '../components/location/mapaddress'
import SideBarContent from '../components/location/sidebarcontent'
import GalleryComponent from '../components/location/gallery'
import LocationDetails from '../components/location/locationdetails'
import { navigate } from "gatsby";

export const query = graphql` 
query locationTemplateQuery($id: String!) {
    location: sanityLocation(id: {eq: $id}) {
  id
  name
  descLong {
    children {
      text
    }
  }
  addr {
    line1
    line2
    code
    city
    state
  }
  descShort
  link {
    home
  }
  geopoint {
    alt
    lat
    lng
  }
  telNum {
    main
    fax
  }
  hrs_247
  hrs {
    wednesdayOpen {
      minute
      hour
    }
    fridayClose {
      minute
      hour
    }
    fridayOpen {
      minute
      hour
    }
    mondayClose {
      minute
      hour
    }
    mondayOpen {
      minute
      hour
    }
    saturdayClose {
      minute
      hour
    }
    saturdayOpen {
      minute
      hour
    }
    sundayClose {
      minute
      hour
    }
    sundayOpen {
      minute
      hour
    }
    thursdayClose {
      minute
      hour
    }
    thursdayOpen {
      minute
      hour
    }
    tuesdayClose {
      minute
      hour
    }
    tuesdayOpen {
      minute
      hour
    }
    wednesdayClose {
      minute
      hour
    }
  }
  specialty {
    id
    name
  }
  slug {
    current
  }
  services {
    _key
    _type
    age {
      min
      max
    }
    diagnosis {
      name
    }
    levelCare {
      name
    }
    serviceLine {
      name
    }
    treatment {
      name
    }
  }
  logo_horizontal {
    asset {
      url
      title
      size
      description
    }
  }
  logo_alt
  gallery {
    alt
    asset {
      url
      title
      size
      description
    }
  }
  }
}
`
const LocationPostTemplate = props => {
  const {data = {}, errors} = props
  const {addr, descLong, gallery, geopoint, hrs, hrs_247, link, logo_horizontal, name, services, specialty, telNum} = data.location

  const number = telNum !== null ? telNum.main : null;
  const banner = gallery[0] !== undefined ? gallery[0].asset.url : null;

  return (
    <Layout>
        {errors && <GraphQLErrorList errors={errors} />}
        {!data.location && <p>No category data</p>}
        <SEO title={name} description={name} />
        <LocationHeader/>
        <Container>
        <div className="sl_content sl_content--location">

          <div className="sl_content__header" style={ { backgroundImage: `url(${banner})`} }>
            <div className="sl_inner">
              <Link className="sl_content__back" to={'/search'} onClick={() => navigate(-1)}>Back To Results</Link>
              <LocationLogo logoObj={logo_horizontal}></LocationLogo>
            </div>{/* end sl_inner */}
          </div>{/* end sl_content__header */}

          <div className="sl_content__main">
            <div className="sl_inner">
              <div className="sl_row">
                <div className="sl_cell sl_content__sidebar large-5 medium-12 small-12">
                  <div className="sl_sidebar">
                    <h1>{name || 'Name missing'}</h1>
                    {name.includes('HealthLinkNow') ?
                      <div className="sl_sidebar__info">
                        <SideBarInfo url={link} number={number}></SideBarInfo>
                        <HoursOfOperation rangeObj={hrs} allDay={hrs_247} ></HoursOfOperation>
                        <div className="sl_sidebar__buttongroup sl_sidebar__hln hide-for-large">
                          <a className="sl_sidebar__phone" href={`tel:${number}`}>Call Now</a>
                          {link !== null ? <a className="sl_sidebar__website" target="_blank" rel="noreferrer" href={link.home}>Visit Website</a> : null }
                        </div>
                      </div>
                    :
                      <div className="sl_sidebar__info">
                        <SideBarInfo url={link} number={number}></SideBarInfo>
                        <HoursOfOperation rangeObj={hrs} allDay={hrs_247} ></HoursOfOperation>
                          <div className="sl_sidebar__address hide-for-large">
                            <p>{addr.line1} {addr.line2}<br/>{addr.city}, {addr.state.toUpperCase()} {addr.code}</p>
                          </div>
                        <div className="sl_sidebar__buttongroup hide-for-large">
                          <a className="sl_sidebar__phone" href={`tel:${number}`}>Call Now</a>
                          {geopoint !== null ? <a className="sl_sidebar__directions" href={`https://www.google.com/maps/dir//${addr.line1},+${addr.city},+${addr.state}+${addr.code}/@${geopoint.lat},${geopoint.lng},17z/`}>Get Directions</a> : null }
                          {link !== null ? <a className="sl_sidebar__website" target="_blank" rel="noreferrer" href={link.home}>Visit Website</a> : null }
                        </div>
                      </div>
                    }

                    {name.includes('HealthLinkNow') ? null :
                      <div className="sl_sidebar__map show-for-large">
                        <MapAddressComponent props={{geopoint, addr, name}}></MapAddressComponent>
                      </div>
                    }

                    <div className="sl_sidebar__content">
                      <SideBarContent props={{link, number, descLong}}></SideBarContent>
                    </div>
                  </div>
                </div>
                <div className="sl_cell sl_content__info large-auto medium-12 small-12">
                  <article>
                    <GalleryComponent gallery={gallery}></GalleryComponent>
                    <LocationDetails services={services} specialty={specialty}></LocationDetails>
                  </article>
                </div>
              </div>{/*end sl_row*/}
            </div>{/*end sl_inner*/}
          </div>{/* end sl_content__main */}
        </div>{/* end sl_content*/}
      </Container>
    </Layout>
  )
}
export default LocationPostTemplate