import React, { useState } from 'react'
import Slider from "react-slick"
import { Modal } from 'react-bootstrap'
import "slick-carousel/slick/slick.css"; 

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <>
    <div
      className="sl_gallery__slider__arrow sl_gallery__slider__arrow--next"
      onClick={onClick}
    >
    <i className="fas fa-chevron-right"></i>
    </div>
    </>
  );
}

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <>
    <div
      className="sl_gallery__slider__arrow sl_gallery__slider__arrow--prev"
      onClick={onClick}
    >
    <i className="fas fa-chevron-left"></i>
    </div>
    </>
  );
}


const GalleryComponent = (gallery) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "sl_gallery__slider",
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />
      }; 

    const canGalleryRender = gallery.gallery[0];
    if (!canGalleryRender) {
      return null ;
    }
    return (
      <>
        <div className="sl_gallery" onClick={handleShow} onKeyDown={handleShow}>
            {/*If less than three images in the gallery*/}
            { gallery.gallery.length < 3 ? 
            <>
                <div className="sl_gallery__container sl_gallery__container--single">
                    <div className="sl_gallery__image" style={{ backgroundImage: `url(${gallery.gallery[0].asset.url})`}}></div>
                </div>
            </>
            //If three or more images in the gallery
            : gallery.gallery.length >= 3 ? 
            <>
                <div className="sl_gallery__container sl_gallery__container--grid">
                    <div className="sl_gallery__image" style={{ backgroundImage: `url(${gallery.gallery[0].asset.url})`}}></div>
                </div>
                <div className="sl_gallery__container sl_gallery__container--grid">
                    <div className="sl_gallery__image" style={{ backgroundImage: `url(${gallery.gallery[1].asset.url})`}}></div>
                </div>
                <div className="sl_gallery__container sl_gallery__container--grid">
                    <div className="sl_gallery__image" style={{ backgroundImage: `url(${gallery.gallery[2].asset.url})`}}></div>
                </div>
            </>
            : null
            }
        </div>

        <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        dialogClassName="sl_modal"
        contentClassName="sl_modal__content"
        centered 
        >
            <Modal.Body>
                <div>
                    <Slider {...settings}>
                        { gallery.gallery.map(( node ) => (
                            <div>
                                <img key={'Slide' + node._key} src={node.asset.url} alt={node.alt}></img>
                            </div>
                        ))}
                    </Slider>
                </div>
            </Modal.Body>
        </Modal>
      </>
    );
};

export default GalleryComponent;