import React from 'react'

const HoursOfOperation = ({rangeObj, allDay}) => {

    if ((rangeObj === null && allDay === false) || null) {
      return (
        <>
        <small>Pending content…</small>
        </>
      );
    } else {

      if (allDay === true) {
        return (
          <>
            <p><strong>Hours of Operation: </strong>
            <span>Open 24 Hours, 7 Days a Week</span>
            </p>
          </>
        );
      } else {
        if (rangeObj) {
          const {fridayClose, fridayOpen, mondayClose, mondayOpen, tuesdayClose, tuesdayOpen, wednesdayClose, wednesdayOpen, thursdayClose, thursdayOpen, saturdayClose, saturdayOpen, sundayClose, sundayOpen} = rangeObj;

            let hours = '';
            if (mondayOpen != null && mondayClose != null) {
                hours += 'Monday: ' + formatTime(mondayOpen.hour, mondayOpen.minute) + ' to ' + formatTime(mondayClose.hour, mondayClose.minute) + "\n";
            }
            else {
                hours += 'Monday: Closed' + "\n";
            }
            if (tuesdayOpen != null && tuesdayClose != null) {
                hours += 'Tuesday: ' + formatTime(tuesdayOpen.hour, tuesdayOpen.minute) + ' to ' + formatTime(tuesdayClose.hour, tuesdayClose.minute) + "\n";
            }
            else {
                hours += 'Tuesday: Closed' + "\n";
            }
            if (wednesdayOpen != null && wednesdayClose != null) {
                hours += 'Wednesday: ' + formatTime(wednesdayOpen.hour, wednesdayOpen.minute) + ' to ' + formatTime(wednesdayClose.hour, wednesdayClose.minute) + "\n";
            }
            else {
                hours += 'Wednesday: Closed' + "\n";
            }
            if (thursdayOpen != null && thursdayClose != null) {
                hours += 'Thursday: ' + formatTime(thursdayOpen.hour, thursdayOpen.minute) + ' to ' + formatTime(thursdayClose.hour, thursdayClose.minute) + "\n";
            }
            else {
                hours += 'Thursday: Closed' + "\n";
            }
            if (fridayOpen != null && fridayClose != null) {
                hours += 'Friday: ' + formatTime(fridayOpen.hour, fridayOpen.minute) + ' to ' + formatTime(fridayClose.hour, fridayClose.minute) + "\n";
            }
            else {
                hours += 'Friday: Closed' + "\n";
            }
            if (saturdayOpen != null && saturdayClose != null) {
                hours += 'Saturday: ' + formatTime(saturdayOpen.hour, saturdayOpen.minute) + ' to ' + formatTime(saturdayClose.hour, saturdayClose.minute) + "\n";
            }
            else {
                hours += 'Saturday: Closed' + "\n";
            }
            if (sundayOpen != null && sundayClose != null) {
                hours += 'Sunday: ' + formatTime(sundayOpen.hour, sundayOpen.minute) + ' to ' + formatTime(sundayClose.hour, sundayClose.minute) + "\n";
            }
            else {
                hours += 'Sunday: Closed';
            }

        return (
          <>
            <p className="sl_hours"><strong>Hours of Operation: </strong><br></br>
                {hours}
                {/*Monday Hours: {mondayOpen.hour}:{mondayOpen.minute} to {mondayClose.hour}:{mondayClose.minute}<br></br>
                Tuesday Hours: {tuesdayOpen.hour}:{tuesdayOpen.minute} to {tuesdayClose.hour}:{tuesdayClose.minute}<br></br>
                Wednesday Hours: {wednesdayOpen.hour}:{wednesdayOpen.minute} to {wednesdayClose.hour}:{wednesdayClose.minute}<br></br>
                Thursday Hours: {thursdayOpen.hour}:{thursdayOpen.minute} to {thursdayClose.hour}:{thursdayClose.minute}<br></br>
                Friday Hours: {fridayOpen.hour}:{fridayOpen.minute} to {fridayClose.hour}:{fridayClose.minute}<br></br>
                Saturday Hours: {saturdayOpen.hour}:{saturdayOpen.minute} to {saturdayClose.hour}:{saturdayClose.minute}<br></br>
                Sunday Hours: {sundayOpen.hour}:{sundayOpen.minute} to {sundayClose.hour}:{sundayClose.minute}<br></br>*/}
            </p>
          </>
        );
      } else {
        return (
          null
        );
      }
      }
    }
};

function formatTime(hour, minute) {
    let ampm = ' AM';
    if (hour > 12) {
        hour = hour - 12;
        ampm = ' PM';
    }

    hour = hour.toString();
    if (hour.startsWith('0')) {
        hour = hour.substring(1);
    }

    return hour + ':' + minute + ampm;
}

export default HoursOfOperation;